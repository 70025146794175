import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// import Header from './header'

import './layout.scss'


// const Header = _ => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//       <h1>{data.site.siteMetadata.title}</h1>
//     )}
//   />)


const Layout = ({ children }) => (
  <StaticQuery
    // query={graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }
    // `}
    query={graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              path
              date
              excerpt
            }
          }
        }
      }
    }
  `}
    render={(data) => {
      return <>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
          <footer className='d-absolute bottom'>
            <small>
              <a href="mailto:hello@gointeractive.se" class="hello font-georgia text-light-gray" target="_blank" rel="noopener noreferrer">hello@gointeractive.se</a>
            </small>
          </footer>
        </div>
      </>

    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
